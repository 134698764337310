import React from "react"
import Helmet from "react-helmet"
import SchemaOrg from "./schemaorg"

function SEO({ title, imgtitle, imgtitleen, description, language, altlanglink, hreflangalt, image, pagetype, pageslug, lastbreadcrumb}) {
  const metaUrl="https://www.berlitz-augsburg.de"
  const metaUrlalt="https://www.berlitz-augsburg.de/"
  const canonicalUrl=`${metaUrl}${pageslug}`
  const hrefLangalt=`${metaUrlalt}${hreflangalt}`
  
  var altde;
  var alten;
  var metaImage;
  if (language === "de") {
    altde=canonicalUrl;
    alten=hrefLangalt;
    metaImage="https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ imgtitle +"-1200w.jpg";
  } else if (language ==="en"){
    altde=hrefLangalt;
    alten=canonicalUrl;
    metaImage="https://res.cloudinary.com/berlitz-augsburg/image/upload/jpg/"+ imgtitleen +"-1200w.jpg";
  } 
  return (
    <React.Fragment>
      <Helmet>
        <html lang={language} />
        <title>{title}</title>        
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl}></link>
        <link rel="alternate" href={altde} hreflang="de-de"></link>
        <link rel="alternate" href={alten} hreflang="en-de"></link>
        <link rel="preconnect" href="https://res.cloudinary.com" crossorigin></link>
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={title}></meta>
        <meta property="og:url" content={metaUrl}></meta>
        <meta property="og:description" content={description}></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:image" content={metaImage}></meta>
        <meta property="og:image:width" content="1200"></meta>
        <meta property="og:image:height" content="800"></meta>
        <meta property="og:image:alt" content={title}></meta>
        <meta name="twitter:title" content={title}></meta>
        <meta name="twitter:description" content={description}></meta>
        <meta name="twitter:card" content={metaImage}></meta>
        <meta name="twitter:image:alt" content={description}></meta>
     </Helmet>
     <SchemaOrg page={pagetype}
                fullpageurl={canonicalUrl}
                image={metaImage}
                pagetitle={title}
                pagedescription={description}
                language={language}
                pagetype={pagetype}
                lastbreadcrumb={lastbreadcrumb}
     />
    </React.Fragment> 
  )
}

export default SEO
