import React, {Component} from 'react'
import styled from 'styled-components'
import DesktopNav from './desktopnav'
import Logo from './logo'
import Headroom from 'react-headroom'
import { Link } from "gatsby"


const NavContainer = styled.nav`
    background-color: #fff;
    padding: 12px;
    padding-left: 0px;

    display: none;

    @media (min-width:1250px) {
        display: block;
        justify-content: space-between;
        max-width: 1280px;
        margin: 0 auto;
    }
`;

const StickyContainer = styled.div`
    @media (min-width:768px) {
        display: flex;
        justify-content: space-between;
        max-width: 1280px;
        margin: 0 auto;
        padding-left: 8px;
    }
`;

const FormButton = styled(Link)`
    min-width: auto;
    background-color:
    rgb(0, 79, 239);
    border-radius: 3px;
    color: #fff !important;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 24px;
    text-decoration: none;
    transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    width: auto;
    position: relative;
    background-image: none;
    line-height: 1.2;
    :hover,
    :focus {
        background-color: rgb(25, 96, 240);
    }
`;
const LPNav = styled.ul`
    display: flex;
    -moz-box-align: center;
    align-items: center;
    list-style: outside none none;
    li {
        margin-right: 16px;
        font-size: 16px;
        line-height: 1.6;
        font-weight: 400;
        color: rgb(255, 255, 255);
        transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
        text-decoration: none;
        white-space: nowrap;
        color: rgb(45, 47, 61);
    }
`

const NavSpacer = styled.div`
    padding-left: 0px;
`;
class StickyNav extends Component {

    render () {

        return (
            <Headroom disableInlineStyles 
                      pinStart={500}
                      calcHeightOnResize={true}
                      onUnpin={() => this.toggleScrollnavopen}
            >
                <NavContainer className="sticky">
                    <StickyContainer>
                        <Logo logoHeight="45px" logoWidth="160px" currenttoplang="de" />
                        {this.props.landingpage ? (
                            <div>
                                
                            {this.props.language === "de" &&
                                <LPNav>
                                    <li>0821 50 82 980</li>
                                    <FormButton href="#kursanfrage" type="button" ><span>Kontaktieren Sie uns</span></FormButton>
                                </LPNav>                    
                            }
                            {this.props.language === "en" &&
                                <LPNav>
                                    <li>0821 50 82 980</li>
                                    <FormButton href="#course-inquiry" type="button" ><span>Contact Us</span></FormButton>
                               </LPNav> 
                            }
                        
                        </div>
                        ) : (
                            <NavSpacer><DesktopNav nofind="true" language={this.props.language}/></NavSpacer>
                        )}
                    </StickyContainer>
                </NavContainer>
            </Headroom>
        )
    }
}

export default StickyNav

