import React, {Component} from 'react'
import { Link } from 'gatsby'
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import styled from 'styled-components';

const NavContainer = styled.nav`
    display: none;
    ul li a{
        color: ${props => props.notfound ? 'rgb(45, 47, 61)' : 'rgb(255, 255, 255)'};
    }
    
    @media (min-width:1250px) {
        display: flex;
        justify-content: space-between;
        max-width: 1280px;
        margin: 0 auto;
    }
`;
const NavList = styled.ul`
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    margin-bottom: 0;

`;

const NavListRight = styled(NavList)`
    margin-left: auto;
`;

const NavListItem = styled.li`
    padding: 8px;
    a {
        background-image: none;
        color: #ffffff;
        font-weight: 400;
        font-size: 14px;
    }
    a:hover{
        color: #fff;
        opacity: 0.8;
    }
`;
const NavListItemRight = styled(NavListItem)`
    margin-right: 0px;
`;

const NavLink = styled(Link)`
    display: flex;
    font-size: 16px;
    line-height: 1.3;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: none;
    white-space: nowrap;
    font-size: 14px;
    background: none;

    :hover{
        opacity: 0.8;
    }
`;
const LangIcon = styled.span`
    display: flex;
    color: rgb(252, 252, 252);
    font-size: 24px;
    margin-right: 8px;

    svg {
        fill: currentColor;
    }
`;

const IconSpan = styled.span`
    display: flex;
    color: rgb(252, 252, 252);
    font-size: 24px;
`;
const LangButton= styled.button`
    padding-right: 0 !important;
    text-transform: uppercase;
    background: none;
font-size: 16px;
line-height: 1.3;
color: rgb(255, 255, 255);
border: none;
transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
text-decoration: none;
white-space: nowrap;
padding: 8px;
    padding-right: 8px;
display: flex;
`;

const PhoneSpan= styled.span`
background-image: none;
color: #ffffff;
font-weight: 400;
font-size: 14px;  
`;

class TopNav extends Component {
    render () {
        
    const language = (
        <Menu>
            <MenuItem>
                <NavLink to={this.props.langlinktopde}>Deutsch</NavLink>
            </MenuItem>
            <MenuItem>
                <NavLink to={this.props.langlinktopen}>English</NavLink>
            </MenuItem>
        </Menu>
    );

        return (
            <NavContainer notfound={this.props.nofind}>
                {this.props.currenttoplang === "de" &&
                <NavList notfound={this.props.nofind}>
                    <NavListItem>
                        <NavLink to="/kontakt/">Kontaktieren Sie uns</NavLink>
                    </NavListItem>
                    <NavListItem>
                        <PhoneSpan>Rufen Sie uns an: 0821 50 82 980</PhoneSpan>
                    </NavListItem>
                </NavList>
                }
                {this.props.currenttoplang === "en" &&
                <NavList>
                    <NavListItem>
                        <NavLink to="/en/contact/">Contact Us</NavLink>
                    </NavListItem>
                    <NavListItem>
                        <PhoneSpan>Call Us: 0821 50 82 980</PhoneSpan>
                    </NavListItem>
                </NavList>
                }
                <NavListRight>
                    <NavListItemRight>
                        <Dropdown overlay={language} placement="bottomRight">
                        <LangButton>
                            <LangIcon>
                                <svg viewBox="0 0 24 24" width="1em" height="1em" focusable="false" aria-hidden="true" role="presentation"><path d="M12 24a12.02 12.02 0 01-2.936-.366 11.959 11.959 0 01-7.153-5.149.751.751 0 01-.109-.171A11.952 11.952 0 010 12c0-2.234.623-4.416 1.801-6.313a.804.804 0 01.11-.173A11.976 11.976 0 0114.831.34a11.978 11.978 0 017.257 5.174.75.75 0 01.111.173A11.955 11.955 0 0124 12c0 2.234-.623 4.416-1.801 6.313a.744.744 0 01-.111.172A11.974 11.974 0 0112 24zm-2.246-1.745c.742.162 1.496.245 2.246.245s1.504-.083 2.246-.245c.563-.9 1.052-2.102 1.427-3.505H8.327c.375 1.404.864 2.605 1.427 3.505zm7.478-3.505a17.432 17.432 0 01-.945 2.832 10.476 10.476 0 003.747-2.832h-2.802zm-13.266 0a10.473 10.473 0 003.746 2.832 17.432 17.432 0 01-.945-2.832H3.966zm17.124-1.5a10.471 10.471 0 001.38-4.5h-4.481a29.288 29.288 0 01-.438 4.5h3.539zm-5.074 0c.271-1.409.433-2.951.472-4.5H7.512c.039 1.55.201 3.092.472 4.5h8.032zm-9.567 0a29.288 29.288 0 01-.438-4.5H1.53c.113 1.586.586 3.13 1.38 4.5h3.539zm16.021-6a10.471 10.471 0 00-1.38-4.5h-3.539c.255 1.418.402 2.928.438 4.5h4.481zm-5.982 0a27.852 27.852 0 00-.472-4.5H7.984a27.965 27.965 0 00-.472 4.5h8.976zm-10.477 0c.036-1.572.183-3.082.438-4.5H2.91a10.471 10.471 0 00-1.38 4.5h4.481zm14.023-6a10.467 10.467 0 00-3.747-2.832c.37.842.687 1.789.945 2.832h2.802zm-4.361 0c-.375-1.403-.864-2.605-1.427-3.505A10.482 10.482 0 0012 1.5c-.749 0-1.504.083-2.246.245-.563.9-1.052 2.101-1.427 3.505h7.346zm-8.905 0c.258-1.042.574-1.99.945-2.832A10.467 10.467 0 003.966 5.25h2.802z"></path></svg>
                            </LangIcon>
                            {this.props.currenttoplang}
                            <IconSpan>
                                <svg width="1em" height="1em" viewBox="0 0 24 24" focusable="false" aria-hidden="true" role="presentation"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" fill="currentColor"></path><path fill="none" d="M0 0h24v24H0V0z"></path></svg>
                            </IconSpan>                           
                        </LangButton>
                    </Dropdown>
                    </NavListItemRight>
                </NavListRight>
            </NavContainer>
        )
    }
}
export default TopNav
