import React from 'react';
import styled from 'styled-components';
import { Link } from "gatsby"
import BerlitzLogo from "../../../assets/berlitz-sprachschule-augsburg-logo.svg"
const LogoWrapper = styled.div`

`;

const LogoLink = styled(Link)`
    text-decoration: none;
    background: none;
    padding: 0;
    display: flex;

    @media (min-width:768px) {

    }
`;


const LogoImg = styled.span`
    width: ${props => props.logowi};
    height: ${props => props.logohe};
`;

const Logo = props => (
    <LogoWrapper>
        { props.currenttoplang === 'de' &&
            <LogoLink to="/" aria-label="Berlitz Sprachschule Augsburg">
                <LogoImg logowi={props.logoWidth} logohe={props.logoHeight}>
                    <BerlitzLogo />
                </LogoImg>
            </LogoLink>
        }
        { props.currenttoplang === 'en' &&
            <LogoLink to="/en/" aria-label="Berlitz Sprachschule Augsburg">
                <LogoImg logowi={props.logoWidth} logohe={props.logoHeight}>
                    <BerlitzLogo />
                </LogoImg>
            </LogoLink>
        }
    </LogoWrapper>
);
export default Logo;