import React from 'react';
import { Link } from "gatsby"
import {Accordion,AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel,} from 'react-accessible-accordion';
import styled from 'styled-components';
import MenuDE from "../../../config/menu-de.yaml"
import MenuEN from "../../../config/menu-en.yaml"

const SidedrawerContainer = styled.div`
    background-color: #FFFFFF;
    position: fixed;
    box-shadow: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 16px;
    overflow-y: auto;
    z-index: 10;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 400ms;
    transform: translateX( ${ props => props.displaySidedrawer ? ("0%") : ("-100%") } );
    visibility: ${ props => props.displaySidedrawer ? ("visible") : ("hidden") } ;
    will-change: transform;
    margin-top: 64px;
`;
const AccordionItemPanelBackground = styled(AccordionItemPanel)`
  background-color:
  rgb(252, 252, 252);
  padding: 12px 0px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 7px 13px inset;
`;


const AccordionButton = styled(AccordionItemButton)`
  cursor: pointer;
  width: 100%;
  text-align: inherit;
  outline: none;
  margin: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(45, 47, 61);
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  padding: 16px 0px 16px 20px;

  @media (min-width:768px) {
      outline-color: -webkit-focus-ring-color;
      outline-style: auto;
  }
  ::after {
    display: inline-block;
    content: '';
    height: 8px;
    width: 8px;
    margin-right: 30px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
    color: rgb(29, 29, 29);
    transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  }
  &[aria-expanded='true']::after {
    transform: rotate(-135deg);
    transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  }
`;
const AccordionHeading = styled(AccordionItemHeading)`
  display: block;
  color: rgb(29, 29, 29);

`;
const MobileNavList = styled.ul`
 
  background-color:
  rgb(252, 252, 252);
  padding: 12px 0px;
  box-shadow:
  rgba(0, 0, 0, 0.02) 0px 7px 13px inset;
`;
const MobileNavListItem = styled.li`
  display: flex;
  font-size: 14px;
  line-height: 1.3;
  color: rgb(45, 47, 61);
  margin: 0px;
  padding: 0px;
  text-align: left;
`;
const MobileNavLink = styled(Link)`
  width: 100%;
  color: 
  rgb(45, 47, 61);
  text-decoration: none;
  background: none;
  font-size: 16px;
  font-weight: 400;
  padding: 16px;
  padding-left: 20px;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  border-bottom: 1px solid rgb(246, 246, 246);
`;
const LangIcon = styled.span`
    display: flex;
    color: rgb(252, 252, 252);
    font-size: 24px;
    margin-right: 8px;

    svg {
        fill: rgb(29, 29, 29);
    }
`;
const LangSpan = styled.span`
color: rgb(45,47,61);
font-size: 18px;
font-weight: 400;
  margin-top: -2px;
  text-transform: uppercase;
  margin-left: 16px;

`;


const SideDrawer = props => {

  return (          
      <SidedrawerContainer displaySidedrawer = {props.displaySidedrawer}>
        {props.currenttoplang === "de" &&
        
          <Accordion allowZeroExpanded={true}>
            <AccordionItem>
                <AccordionHeading>
                    <AccordionButton>
                        Eine Sprache lernen
                    </AccordionButton>
                </AccordionHeading>
                  <AccordionItemPanelBackground>
                  {MenuDE.sprachelernen.map((data) => {
                    return <MobileNavListItem><MobileNavLink to={data.link}>{data.item}</MobileNavLink></MobileNavListItem>
                    })}
                  </AccordionItemPanelBackground>
            </AccordionItem>

            <AccordionItem>
                <AccordionHeading>
                    <AccordionButton>
                        Erwachsene
                    </AccordionButton>
                </AccordionHeading>
                <AccordionItemPanelBackground>
                  {MenuDE.erwachsene.map((data) => {
                    return <MobileNavListItem><MobileNavLink to={data.link}>{data.item}</MobileNavLink></MobileNavListItem>
                  })}
                </AccordionItemPanelBackground>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeading>
                    <AccordionButton>
                        Firmen
                    </AccordionButton>
                </AccordionHeading>
                <AccordionItemPanelBackground>
                  {MenuDE.firmen.map((data) => {
                    return <MobileNavListItem><MobileNavLink to={data.link}>{data.item}</MobileNavLink></MobileNavListItem>
                  })}
                </AccordionItemPanelBackground>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeading>
                    <AccordionButton>
                        Geförderte Kurse
                    </AccordionButton>
                </AccordionHeading>
                <AccordionItemPanelBackground>
                  {MenuDE.gefoerdertekurse.map((data) => {
                    return <MobileNavListItem><MobileNavLink to={data.link}>{data.item}</MobileNavLink></MobileNavListItem>
                  })}
                </AccordionItemPanelBackground>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeading>
                    <AccordionButton>
                        Kids & Teens
                    </AccordionButton>
                </AccordionHeading>
                <AccordionItemPanelBackground>
                  {MenuDE.kidsteens.map((data) => {
                    return <MobileNavListItem><MobileNavLink to={data.link}>{data.item}</MobileNavLink></MobileNavListItem>
                  })}
                </AccordionItemPanelBackground>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeading>
                    <AccordionButton>
                        Kontakt
                    </AccordionButton>
                </AccordionHeading>
                <AccordionItemPanelBackground>
                  <MobileNavLink to="/kontakt/">Kontaktieren Sie uns</MobileNavLink>
                </AccordionItemPanelBackground>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeading>
                    <AccordionButton>
                    <LangIcon>
                      <svg viewBox="0 0 24 24" width="1em" height="1em" focusable="false" aria-hidden="true" role="presentation"><path d="M12 24a12.02 12.02 0 01-2.936-.366 11.959 11.959 0 01-7.153-5.149.751.751 0 01-.109-.171A11.952 11.952 0 010 12c0-2.234.623-4.416 1.801-6.313a.804.804 0 01.11-.173A11.976 11.976 0 0114.831.34a11.978 11.978 0 017.257 5.174.75.75 0 01.111.173A11.955 11.955 0 0124 12c0 2.234-.623 4.416-1.801 6.313a.744.744 0 01-.111.172A11.974 11.974 0 0112 24zm-2.246-1.745c.742.162 1.496.245 2.246.245s1.504-.083 2.246-.245c.563-.9 1.052-2.102 1.427-3.505H8.327c.375 1.404.864 2.605 1.427 3.505zm7.478-3.505a17.432 17.432 0 01-.945 2.832 10.476 10.476 0 003.747-2.832h-2.802zm-13.266 0a10.473 10.473 0 003.746 2.832 17.432 17.432 0 01-.945-2.832H3.966zm17.124-1.5a10.471 10.471 0 001.38-4.5h-4.481a29.288 29.288 0 01-.438 4.5h3.539zm-5.074 0c.271-1.409.433-2.951.472-4.5H7.512c.039 1.55.201 3.092.472 4.5h8.032zm-9.567 0a29.288 29.288 0 01-.438-4.5H1.53c.113 1.586.586 3.13 1.38 4.5h3.539zm16.021-6a10.471 10.471 0 00-1.38-4.5h-3.539c.255 1.418.402 2.928.438 4.5h4.481zm-5.982 0a27.852 27.852 0 00-.472-4.5H7.984a27.965 27.965 0 00-.472 4.5h8.976zm-10.477 0c.036-1.572.183-3.082.438-4.5H2.91a10.471 10.471 0 00-1.38 4.5h4.481zm14.023-6a10.467 10.467 0 00-3.747-2.832c.37.842.687 1.789.945 2.832h2.802zm-4.361 0c-.375-1.403-.864-2.605-1.427-3.505A10.482 10.482 0 0012 1.5c-.749 0-1.504.083-2.246.245-.563.9-1.052 2.101-1.427 3.505h7.346zm-8.905 0c.258-1.042.574-1.99.945-2.832A10.467 10.467 0 003.966 5.25h2.802z"></path></svg>                    
                      <LangSpan>{props.currenttoplang}</LangSpan>
                    </LangIcon>
                    </AccordionButton>
                </AccordionHeading>
                <AccordionItemPanelBackground>
                  <MobileNavList>
                    <MobileNavListItem><MobileNavLink to={props.langlinktopde}>Deutsch</MobileNavLink></MobileNavListItem>
                    <MobileNavListItem><MobileNavLink to={props.langlinktopen}>English</MobileNavLink></MobileNavListItem>
                  </MobileNavList>
                </AccordionItemPanelBackground>
            </AccordionItem>
          </Accordion>
        }
        {props.currenttoplang === "en" &&
          <Accordion allowZeroExpanded={true}>
            <AccordionItem>
                <AccordionHeading>
                    <AccordionButton>
                        Learn a Language
                    </AccordionButton>
                </AccordionHeading>
                  <AccordionItemPanelBackground>
                  {MenuEN.learnalanguage.map((data) => {
                    return <MobileNavListItem><MobileNavLink to={data.link}>{data.item}</MobileNavLink></MobileNavListItem>
                    })}
                  </AccordionItemPanelBackground>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeading>
                    <AccordionButton>
                        Adults
                    </AccordionButton>
                </AccordionHeading>
                <AccordionItemPanelBackground>
                  {MenuEN.adults.map((data) => {
                    return <MobileNavListItem><MobileNavLink to={data.link}>{data.item}</MobileNavLink></MobileNavListItem>
                  })}
                </AccordionItemPanelBackground>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeading>
                    <AccordionButton>
                        Business
                    </AccordionButton>
                </AccordionHeading>
                <AccordionItemPanelBackground>
                  {MenuEN.business.map((data) => {
                    return <MobileNavListItem><MobileNavLink to={data.link}>{data.item}</MobileNavLink></MobileNavListItem>
                  })}
                </AccordionItemPanelBackground>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeading>
                    <AccordionButton>
                        Geförderte Kurse
                    </AccordionButton>
                </AccordionHeading>
                <AccordionItemPanelBackground>
                  {MenuEN.subsidizedcourses.map((data) => {
                    return <MobileNavListItem><MobileNavLink to={data.link}>{data.item}</MobileNavLink></MobileNavListItem>
                  })}
                </AccordionItemPanelBackground>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeading>
                    <AccordionButton>
                        Kids & Teens
                    </AccordionButton>
                </AccordionHeading>
                <AccordionItemPanelBackground>
                  {MenuEN.kidsandteens.map((data) => {
                    return <MobileNavListItem><MobileNavLink to={data.link}>{data.item}</MobileNavLink></MobileNavListItem>
                  })}
                </AccordionItemPanelBackground>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeading>
                    <AccordionButton>
                        Contact
                    </AccordionButton>
                </AccordionHeading>
                <AccordionItemPanelBackground>
                  <MobileNavLink to="/en/contact/">Contact Us</MobileNavLink>
                </AccordionItemPanelBackground>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeading>
                    <AccordionButton>
                    <LangIcon>
                      <svg viewBox="0 0 24 24" width="1em" height="1em" focusable="false" aria-hidden="true" role="presentation"><path d="M12 24a12.02 12.02 0 01-2.936-.366 11.959 11.959 0 01-7.153-5.149.751.751 0 01-.109-.171A11.952 11.952 0 010 12c0-2.234.623-4.416 1.801-6.313a.804.804 0 01.11-.173A11.976 11.976 0 0114.831.34a11.978 11.978 0 017.257 5.174.75.75 0 01.111.173A11.955 11.955 0 0124 12c0 2.234-.623 4.416-1.801 6.313a.744.744 0 01-.111.172A11.974 11.974 0 0112 24zm-2.246-1.745c.742.162 1.496.245 2.246.245s1.504-.083 2.246-.245c.563-.9 1.052-2.102 1.427-3.505H8.327c.375 1.404.864 2.605 1.427 3.505zm7.478-3.505a17.432 17.432 0 01-.945 2.832 10.476 10.476 0 003.747-2.832h-2.802zm-13.266 0a10.473 10.473 0 003.746 2.832 17.432 17.432 0 01-.945-2.832H3.966zm17.124-1.5a10.471 10.471 0 001.38-4.5h-4.481a29.288 29.288 0 01-.438 4.5h3.539zm-5.074 0c.271-1.409.433-2.951.472-4.5H7.512c.039 1.55.201 3.092.472 4.5h8.032zm-9.567 0a29.288 29.288 0 01-.438-4.5H1.53c.113 1.586.586 3.13 1.38 4.5h3.539zm16.021-6a10.471 10.471 0 00-1.38-4.5h-3.539c.255 1.418.402 2.928.438 4.5h4.481zm-5.982 0a27.852 27.852 0 00-.472-4.5H7.984a27.965 27.965 0 00-.472 4.5h8.976zm-10.477 0c.036-1.572.183-3.082.438-4.5H2.91a10.471 10.471 0 00-1.38 4.5h4.481zm14.023-6a10.467 10.467 0 00-3.747-2.832c.37.842.687 1.789.945 2.832h2.802zm-4.361 0c-.375-1.403-.864-2.605-1.427-3.505A10.482 10.482 0 0012 1.5c-.749 0-1.504.083-2.246.245-.563.9-1.052 2.101-1.427 3.505h7.346zm-8.905 0c.258-1.042.574-1.99.945-2.832A10.467 10.467 0 003.966 5.25h2.802z"></path></svg>                    
                    </LangIcon>
                    <LangSpan>{props.currenttoplang}</LangSpan>
                    </AccordionButton>
                </AccordionHeading>
                <AccordionItemPanelBackground>
                  <MobileNavList>
                    <MobileNavListItem><MobileNavLink to={props.langlinktopde}>Deutsch</MobileNavLink></MobileNavListItem>
                    <MobileNavListItem><MobileNavLink to={props.langlinktopen}>English</MobileNavLink></MobileNavListItem>
                  </MobileNavList>
                </AccordionItemPanelBackground>
            </AccordionItem>
          </Accordion>
        }
      </SidedrawerContainer>
  );

}
export default SideDrawer;