import React from 'react';
import Helmet from 'react-helmet';

export default React.memo(
  ({
    title,
    pagetitle,
    fullpageurl,
    canonicalUrl,
    homepagedescription,
    pagedescription,
    language,
    image,
    pagetype,
    lastbreadcrumb
  }) => {

    const schemaHomepage = [
      {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "@id": "https://www.berlitz-augsburg.de/#localbusiness",
        "name": "Berlitz Sprachschule Augsburg",
        "description": homepagedescription,
        "url": "https://www.berlitz-augsburg.de",
        "logo":{
          "@type":"ImageObject",
          "@id":"https://www.berlitz-augsburg.de/#logo",
          "url":"https://www.berlitz-augsburg.de/berlitz-sprachschule-augsburg-logo.jpg",
          "width":154,
          "height":45,
          "caption":"Berlitz Sprachschule Augsburg"
        },
        "image": {
          "@type": "ImageObject",
          "@id": "https://www.berlitz-augsburg.de/#image",
          "url": image,
          "caption": "Berlitz Sprachschule Augsburg"
        },
        "telePhone": "0821 50 82 980",
        "faxNumber": "0821 50 82 978",
        "address": {
          "@type": "PostalAddress",
          "@id":"https://www.berlitz-augsburg.de/#address",
          "streetAddress": "Karlstraße 12",
          "addressLocality": "Augsburg",
          "addressRegion": "",
          "postalCode": "86150"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "48.370400",
          "longitude": "10.896027"
        },
        "hasMap": "https://www.google.de/maps/place/Berlitz+Sprachschule+Augsburg/@48.37031,10.89611,15z/data=!4m5!3m4!1s0x0:0xe1d2d893817b542d!8m2!3d48.37031!4d10.89611",
        
        "sameAs" : [ 
          "https://de-de.facebook.com/BerlitzAugsburg",
          "https://twitter.com/berlitzaugsburg",
          "https://www.instagram.com/berlitz_augsburg/",
          "https://www.xing.com/companies/berlitzsprachcenteraugsburg",
          "https://www.linkedin.com/company/berlitz-sprachschule-augsburg" 
        ],
        "openingHoursSpecification": [
          {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
            ],
            "opens": "08:00",
            "closes": "19:00"
          },
          {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Saturday"
            ],
            "opens": "08:00",
            "closes": "12:00"
          }
        ],
      },
    ];

    const schemaPage = [
      {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "url": fullpageurl,
        "name": pagetitle,
        "description": pagedescription,
        "inlanguage": language,
        "breadcrumb":{
          "@type":"BreadcrumbList",
          "itemListElement":[
            {
              "@type":"ListItem",
              "position":"1",
              "item":{
              "@type":"WebSite",
              "@id":"http://www.berlitz-augsburg.de/",
              "name":"Home"
              }
              },
              {
                "@type":"ListItem",
                "position":"2",
                "item":{
                "@type":"WebPage",
                "@id": fullpageurl,
                "name": lastbreadcrumb
              }
            }
          ]},
        "publisher": {
          "@type": "LocalBusiness",
          "@id": "https://www.berlitz-augsburg.de/#LocalBusiness",
          "name": "Berlitz Sprachschule Augsburg",
          "address": "https://www.berlitz-augsburg.de/#address",
          "telePhone": "0821 50 82 980",
          "image": "https://www.berlitz-augsburg.de/#image"
        }
      }
    ];

    return (
      <Helmet>
        {pagetype === "index-page" &&
          <script type="application/ld+json">{JSON.stringify(schemaHomepage)}</script>
        }
        {pagetype === "default-page" &&
          <script type="application/ld+json">{JSON.stringify(schemaPage)}</script>
        }
        {pagetype === "language-page" &&
          <script type="application/ld+json">{JSON.stringify(schemaPage)}</script>
        }
        {pagetype === "kontakt-page" &&
          <script type="application/ld+json">{JSON.stringify(schemaPage)}</script>
        }
        {pagetype === "englisch-beruf" &&
          <script type="application/ld+json">{JSON.stringify(schemaPage)}</script>
        }
      </Helmet>
    );
  },
);
