import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  body {
    background-color: rgb(255, 255, 255) !important;
    letter-spacing: 0.3px;
    padding: 0;
    margin: 0;
    font-family: 'IBM Plex Sans', sans-serif !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    letter-spacing: 0.3px !important;
    line-height: 1.4 !important; 

    @media (min-width:576px) {
        margin-top: 0px;
    }

    }
    body.true {
        height: 100%;
        overflow: hidden;
        width: 100%;
        position: fixed;
    }
    ::-moz-selection {
        color: #1D1D1D;
        background-color: #3DDC97;
    }
    
    ::selection {
        color: #1D1D1D;
        background-color: #3DDC97;
    }
    
    html {
        box-sizing: border-box;

    }
    
    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
    
    html,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    form {
        width: 100%;
    }
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section {
        display: block;
    }
    
    body {
        line-height: 1;
        font-variant-ligatures: none;
    }
    
    ol,
    ul {
        list-style: none;
    }
    
    blockquote,
    q {
        quotes: none;
    }
    
    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
        content: '';
        content: none;
    }
    
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    
    img {
        display: block;
    }
    
    button,
    select {
        appearance: none;
        border: none;
        font: inherit;
        letter-spacing: inherit;
        background-color: transparent;
        padding: 0;
    }
    
    textarea,
    input {
        font: inherit;
    }
    strong {
        font-weight: 500;
    }
    h1 {
        font-size: 50px;
    }
    a {
        font-size: inherit;
        display: inline;
        cursor: pointer;
        color: rgb(45, 47, 61);
        background-position: center bottom;
        background-size: auto 20%;
        background-repeat: repeat-x;
        background-image: linear-gradient(to right, rgb(80, 223, 161), rgb(80, 223, 161));
        text-decoration: none;
        
    }

    h2 {
        color: #2D2F3D;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.3;
        margin: 0 0 12px 0;
        
        @media (min-width:576px) {
            font-size: 32px;
                }
    }
    p {
        color: #2D2F3D;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.6;
        margin: 0 0 24px 0;
        text-align: left;

        @media (min-width:576px) {
                font-size: 18px;
        }
        
    }
    h3 {
        color: rgb(45, 47, 61);
        text-align: left;
        font-size: 18px;
        font-weight: 400;
        line-height: 2.5;
        margin: 0px 0px 16px;

        @media (min-width:576px) {
            font-size: 21px;
        }
    }

    h5 {
        color: #1D1D1D;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.6;
        margin: 0 0 0 0;

        @media (min-width:576px) {
            font-size: 16px;
        }
    }
    .footer-svg {
        width: 92px;
        height: 20px;
    }
    .headroom {
        display: none;
        @media (min-width:768px) {
            display: block;
        }
    }
    .headroom-wrapper {
        height: 0px !important;
    }
    .headroom--unfixed {
        visibility: hidden;
        background:
        rgb(255, 255, 255) none repeat scroll 0% 0%;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        box-shadow:
        rgba(0, 0, 0, 0.09) 0px 16px 32px;
        will-change: transform, opacity;
        opacity: 0;
        transform: translateY(-100%);
        pointer-events: none;
        transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 120ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    }
    .headroom--unpinned,
    .headroom--scrolled {
        visibility: visible;
        background:
        rgb(255, 255, 255) none repeat scroll 0% 0%;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        box-shadow:
        rgba(0, 0, 0, 0.09) 0px 16px 32px;
        will-change: transform, opacity;
        opacity: 1;
        transform: translateY(0px);
        pointer-events: auto;
        transition: opacity 0ms ease 0s, transform 400ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    }
    #page-banner::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-color: rgba(45, 47, 61, 0.6) !important;
        opacity: 1 !important;
        background-image: none !important;
        }

    .content-list ul {
        column-gap: 64px;
        padding-left: 32px;
        padding-bottom: 24px;
        color: inherit;
        margin: 0px;
    }
    .content-list li {
        position: relative;
        padding: 4px 0px;
        break-inside: avoid;
        width: 100%;
        color:
        rgb(45, 47, 61);
        font-size: 16px;
        font-weight: 300;
        line-height: 1.6;
        margin: 0px 0px 8px;
        font-size: 18px;
    }
    .content-list li::before {
        content: "●";
        font-family: "Arial, Helvetica, sans-serif";
        display: inline-block;
        position: absolute;
        left: -24px;
        top: 10px;
        font-size: 12px;
        color:
        rgb(0, 79, 239);
    }
    
    

    .video-16x9 {
        display: block;
        position: relative;
        padding-top: 56.25%;
        margin-bottom: 50px;
        margin-top: 20px;
    }
    
    .video-16x9 iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    a.anchor {
        display: block;
        position: relative;
        top: -135px;
        visibility: hidden;
    }
    ol {
        list-style-type: decimal;
        column-gap: 64px;
        padding-left: 32px;
        color: inherit;
        margin: 0px;
      }
    ol li {
        color: #2D2F3D;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.6;
        margin: 0 0 24px 0;
        text-align: left;
    }

    h4 {
        color: rgb(45, 47, 61);
        font-size: 18px;
        font-weight: 400;
        line-height: 1.3;
        margin: 0px 0px 16px;
    }
    @media only screen and (min-width:576px) {
        h4 {
            font-size: 18px;
        }
    }
    .rc-dropdown::before {
        position: absolute;
        top: -7px;
        right: 0;
        bottom: -7px;
        left: -7px;
        z-index: -9999;
        opacity: 0.0001;
        content: ' ';
    }

    .rc-dropdown {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: 'tnum';
        position: absolute;
        top: -9999px;
        left: -9999px;
        z-index: 1050;
        display: block;
    }
    .rc-dropdown-menu {
        margin-top: 5px;
        padding: 12px 24px;
    }
    .rc-dropdown-menu {
        position: relative;
        text-align: left;
        list-style-type: none;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 2px;
        outline: none;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    .rc-dropdown ul::before {
        content: "";
        top: -17px;
        position: absolute;
        width: 10px;
        height: 10px;
        border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) white;
        border-style: solid;
        border-width: 10px;
        -o-border-image: none 100% / 1 / 0 stretch;
        border-image: none 100% / 1 / 0 stretch;
        left: 80%;
        transform: translateX(-70%);
        transform-origin: center center 0px;
        outline: currentcolor none medium;
        pointer-events: none;
        -webkit-filter: drop-shadow(rgb(246, 246, 246) 0px -1px 0px);
        filter: drop-shadow(rgb(246, 246, 246) 0px -1px 0px);
    }
    .rc-dropdown-menu-item {
        text-align: left;
        font-size: 16px;
        line-height: 1.3;
        font-weight: 400;
        transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
        text-decoration: none;
        white-space: nowrap;
        color: rgb(45, 47, 61);
        padding: 8px 0px;
        display: block;
    }
    .rc-dropdown-menu-item > a, .rc-dropdown-menu-submenu-title > a {
        display: block;

        padding: 5px 0px;
        color: rgba(0, 0, 0, 0.65);
        transition: all 0.3s;
    }
    .rc-dropdown-hidden {
        display: none;
    }

    .rc-dropdown-menu-item, .rc-dropdown-menu-submenu-title {
        clear: both;
        margin: 0;
        padding: 5px 12px;
        color: rgba(0, 0, 0, 0.65);
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        white-space: nowrap;
        cursor: pointer;
        transition: all 0.3s;
    }
    picture {
    width: 100%;
    z-index: -1;
    display: flex;
    background-color: rgba(45, 47, 61, 0.6);
    }

    picture > img {
      object-fit: cover; 
      height: auto;
      width:100%;
    }
    @media (max-width:767px) {
        picture.home > img {
            width: 100%;
            object-position: 40% 0;
            height: 520px;
            object-fit: none;
          }
    }
    body.404 > li > button {
        color: rgb(45, 47, 61) !important;
    }
    .404 button,
    .sticky {
        color: rgb(45, 47, 61) !important;
        box-shadow: none;
    }
    .404 button:hover,
    .sticky button:hover {
        color: rgb(45, 47, 61);
        opacity: 0.8;
    }
    .404 span,
    .sticky span {
        color: rgb(45, 47, 61);
        box-shadow: none;
    }
    button {
        color: #fff;
      }
    .react-tabs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }
    .true {
    max-height: 320px !important;
    min-height: 320px !important;
    }

    .efb {
        margin-top: 40px;
        margin-bottom: 40px;
        }
    .efb h3 {
        font-weight: 500;
        margin-bottom: 0;
    }
    .efb .trainingsort p {
        line-height: 1.4;
    }
    .efb .trainingsort {
        margin-bottom: 22px;
    }
    .levels {
        font-size: 20px;
        margin: 0px 0px 24px;
        display: inline;
        padding: 4px;
        line-height: 1.9;
        font-weight: 500;
        color: rgb(255, 255, 255);
        background: rgb(0, 79, 239);
        box-shadow: rgb(0, 79, 239) 8px 0px 0px, rgb(0, 79, 239) -8px 0px 0px;
        position: relative;
        left: 8px;
        margin-bottom: 10px;
    }
    
    .leveldescriptions {
        width: 80%; 
        margin-left: 40px; 
        margin-bottom: 32px;

        @media (max-width:576px) {
            width: 100%;
            margin-left: 0px;
        }
    }
    #coursedates {
        position: relative;
        top: -1000px;
        @media (max-width:992px) {
            position: relative;
            top: -70px;
        }
    }
    .bluespan{
        color: rgb(0,79,239);
    }

`;

