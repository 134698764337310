import React from 'react';
import { Link } from "gatsby"
import styled from 'styled-components';
import Container from '../container'

const SubFooter = styled.div`
  align-items: center;
  display: flex;
  background: #F6F6F6;
  color: #2D2F3D;
  font-size: 14px;
  padding: 16px;
`;

const SubFooterRow = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  line-height: 1.3;
  width: 100%;

  @media (min-width:992px) {
        flex-direction: row;
  }
`;

const CopyRight = styled.div`
  text-align: center;

  @media (min-width:992px) {
    max-width: 50%;
    text-align: left;
    margin-top: 0;
  }
`;

const SubFooterNav = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  font-weight: 400;

  @media (min-width:992px) {
    align-items: center;
    flex-direction: row;
    margin-bottom: 0;
  }
`;

const SubFooterNavItem = styled.li`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;

  @media (min-width:992px) {
        display: inline-block;
        justify-content: flex-start;
        margin-bottom: 0;
        margin-left: 16px;
  }

  a {
    color: #2D2F3D;
    font-weight: 400;
    text-decoration: none;
    background: none;
  }

  a:hover {
    color: #004FEF;
  }
`;
const SubFooterMenu = props => (
<SubFooter>
{props.currentlangfooter === "de" &&
 <Container>
   <SubFooterRow>
    <CopyRight>© 2023 Berlitz Sprachschule Augsburg - Selbständiges Franchise-Unternehmen</CopyRight>
    <SubFooterNav>
      <SubFooterNavItem><Link to="/impressum/">Impressum</Link></SubFooterNavItem>
      <SubFooterNavItem><Link to="/agb/">AGB</Link></SubFooterNavItem>
      <SubFooterNavItem><Link to="/datenschutz/">Datenschutz</Link></SubFooterNavItem>
      <SubFooterNavItem><Link to="/sitemap/">Sitemap</Link></SubFooterNavItem>
    </SubFooterNav>
   </SubFooterRow>
   </Container>
}
{props.currentlangfooter === "en" &&
 <Container>
   <SubFooterRow>
    <CopyRight>© 2023 Berlitz Language School Augsburg - This center is independently owned and operated</CopyRight>
    <SubFooterNav>
      <SubFooterNavItem><Link to="/en/imprint/">Imprint</Link></SubFooterNavItem>
      <SubFooterNavItem><Link to="/en/terms-conditions/">Terms & Conditions</Link></SubFooterNavItem>
      <SubFooterNavItem><Link to="/en/data-protection/">Data Protection</Link></SubFooterNavItem>
      <SubFooterNavItem><Link to="/en/sitemap/">Sitemap</Link></SubFooterNavItem>
    </SubFooterNav>
   </SubFooterRow>
   </Container>
}
</SubFooter>
);
export default SubFooterMenu;