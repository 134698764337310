import React from 'react';
import styled from 'styled-components';
import { Link } from "gatsby"

const MenutoggleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: ${ props => props.displaySidedrawer ? ("8px") : ("8px") };
`;

const MenutoggleButton = styled.button`
    padding: 16px;
`;

const MenutoggleLineTop = styled.span`
    width: 24px;
    height: 2px;
    margin: 5px auto;
    background-color: #004FEF;
    display: block;
    transition: 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: ${ props => props.displaySidedrawer ? ("translateY(7px) rotate(45deg)") : ("initial") };
`;

const MenutoggleLineMiddle = styled.span`
    width: 24px;
    height: 2px;
    margin: 5px auto;
    background-color: #004FEF;
    display: block;
    opacity: ${ props => props.displaySidedrawer ? ("0") : ("1") };
    transition: 500ms cubic-bezier(0.165, 0.84, 0.44, 1);

`;
const MenutoggleLineBottom = styled.span`
    width: 24px;
    height: 2px;
    margin: 5px auto;
    background-color: #004FEF;
    display: block;
    transition: 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: ${ props => props.displaySidedrawer ? ("translateY(-7px) rotate(-45deg)") : ("initial") };
`;
const PhoneIcon = styled(Link)`
    transition: all 120ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    padding-right: 8px;
    display: flex;
    font-size: 24px;
    background: none;
    svg {
        color:rgb(0, 79, 239);   
    }
`;

const SideDrawer = props => (
    <MenutoggleContainer displaySidedrawer={props.displaySidedrawer}>
        <MenutoggleButton onClick={props.click} aria-label="Toggle Mobile Navigation">
            <MenutoggleLineTop displaySidedrawer={props.displaySidedrawer}/>
            <MenutoggleLineMiddle displaySidedrawer={props.displaySidedrawer}/>
            <MenutoggleLineBottom displaySidedrawer={props.displaySidedrawer}/>
        </MenutoggleButton>
    </MenutoggleContainer>
);
export default SideDrawer;

