import styled from 'styled-components'

const CenteringDiv = styled.div`
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;

    @media only screen and (min-width:62em) {
        justify-content: center;

    }
    @media (max-width:768px) {
        justify-content: center;
    }
`;

export default CenteringDiv