import React from 'react'
import styled from 'styled-components'
import Logo from './logo'
import MenutoggleButton from './menutogglebutton'

const MobilenavWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 8px 20px;
    z-index: 11;

    @media (min-width:1249px) {
        display: none;
    }
`;
const MobilenavContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: ${ props => props.displaySidedrawer ? ("rgba(0, 0, 0, 0.01) 0px 4px 10px") : ("none") };
    background-color: #FFFFFF;
    height: 64px;
    padding-left: 20px;
    z-index: 15;


    @media (min-width:576px) {
        padding-left: 16px;
    }
`;


const MobileNav = props => (
    <MobilenavWrapper>
        <MobilenavContainer displaySidedrawer={props.displaySidedrawer}>
            <Logo logoHeight="34px" logoWidth="120px" currenttoplang={props.currenttoplang}/>
            <MenutoggleButton click={props.toggleSidedrawer} displaySidedrawer={props.displaySidedrawer}/>
        </MobilenavContainer>
    </MobilenavWrapper>
  );
  export default MobileNav;
