import styled from 'styled-components'

const Row = styled.div`
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    @media only screen and (min-width:62em) {
        justify-content: center;
    }
`;

export default Row