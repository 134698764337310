import React, {Component} from 'react'
import styled from 'styled-components'
import TopNav from './topnav'
import MobileNav from './mobilenav'
import DesktopNav from './desktopnav'
import StickyNav from './stickynav'
import Logo from './logo'
import SideDrawer from './sidedrawer'
import BodyClassName from 'react-body-classname'
import { Link } from "gatsby"

const NavWrapper = styled.div`
    position: relative;
    z-index: 2;
    padding: 12px 0px;
    padding-bottom: 0px;
    padding-top: ${ props => props.landingpage ? ("32px") : ("12px") } ;
`;

const NavContainer = styled.nav`
    display: none;

    @media (min-width:1250px) {
        display: flex;
        justify-content: space-between;
        max-width: 1280px;
        margin: 0 auto;
        // visibility: ${ props => props.toggleScrollnavopen ? ("visible") : ("hidden") } ;
    }
`;
const FormButton = styled(Link)`
    in-width: auto;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 24px;
    text-decoration: none;
    transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    width: auto;
    background-color:
    transparent;
    color:
    rgb(255, 255, 255);
    border: 2px solid;
    position: relative;
    background-image: none;
    line-height: 1.2;
    :hover,
    :active,
    :focus {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.1);
    }
`
const LPNav = styled.ul`
    display: flex;
    -moz-box-align: center;
    align-items: center;
    list-style: outside none none;
    li {
        margin-right: 16px;
        font-size: 16px;
        line-height: 1.6;
        font-weight: 400;
        color: rgb(255, 255, 255);
        transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
        text-decoration: none;
        white-space: nowrap;
    }
`
class MainNav extends Component {

    state = {
        displaySidedrawer: false,
        scrollnavopen: false,
      };
    
    toggleSidedrawer = () => {
        this.setState((prevState) => {
            return {
                displaySidedrawer: !prevState.displaySidedrawer,

            };
        });
    };

    toggleScrollnavopen = () => {
        this.setState((prevState) => {
            return {
                scrollnavopen: !prevState.scrollnavopen,

            };
        });
    };

    render () {
        return (
                <NavWrapper landingpage={this.props.landingpage}> 
                    <BodyClassName className={this.state.displaySidedrawer}/>
                    <MobileNav toggleSidedrawer={this.toggleSidedrawer} displaySidedrawer={this.state.displaySidedrawer} currenttoplang={this.props.currentlang}/>
                    <SideDrawer displaySidedrawer={this.state.displaySidedrawer} langlinktopde={this.props.langlinkde} langlinktopen={this.props.langlinken} currenttoplang={this.props.currentlang}/>
                    {this.props.landingpage !== true &&
                        <TopNav langlinktopde={this.props.langlinkde} langlinktopen={this.props.langlinken} currenttoplang={this.props.currentlang} nofind={this.props.nofind}/>
                    }
                    <NavContainer className={this.state.scrollnavopen}>
                        <Logo logoHeight="45px" logoWidth="160px" currenttoplang={this.props.currentlang} />
                        {this.props.landingpage ? (
                            <div>
                                
                                {this.props.currentlang === "de" &&
                                    <LPNav>
                                        <li>0821 50 82 980</li>
                                        <FormButton href="#kursanfrage" type="button" ><span>Kontaktieren Sie uns</span></FormButton>
                                    </LPNav>                    
                                }
                                {this.props.currentlang === "en" &&
                                    <LPNav>
                                        <li>0821 50 82 980</li>
                                        <FormButton href="#course-inquiry" type="button" ><span>Contact Us</span></FormButton>
                                   </LPNav> 
                                }
                            
                            </div>

                            ) : (
                            <DesktopNav language={this.props.currentlang} nofind={this.props.nofind}/>
                            )}
                    </NavContainer>
                    <StickyNav toggleScrollnavopen={this.toggleScrollnavopen} language={this.props.currentlang} landingpage={this.props.landingpage} nofind="true"/>
                </NavWrapper>  
        )
    }
}

export default MainNav