import React, {Component} from 'react'
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import styled from 'styled-components'
import { Link } from "gatsby"
import MenuDE from "../../../config/menu-de.yaml"
import MenuEN from "../../../config/menu-en.yaml"
import NavIcon from "../../../assets/navicon.svg"


const DesktopnavWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const NavList = styled.ul`
    display: flex;
    align-items: center;
`;
const NavLi = styled.li`
    position: relative;
    margin-left: 12px;
    padding-bottom: 8px;
    margin-top: 14px;
    display: flex;
    align-items: center;

    @media (min-width:992px) {
        margin-left: 16px;
    }
`;
const NavLink = styled(Link)`
    background: none;
`;

const IconSpan = styled.span`
    display: flex;
    color: ${props => props.notfound ? 'rgb(45, 47, 61)' : 'rgb(255, 255, 255)'};
    font-size: 24px;
`;

const Button = styled.button`
    background: none;
    font-size: 16px;
    line-height: 1.3;
    color: ${props => props.notfound ? 'rgb(45, 47, 61)' : 'rgb(255, 255, 255)'};
    border: none;
    font-weight: 400;
    
    text-decoration: none;
    white-space: nowrap;
    padding: 8px;
    display: flex;

    :hover {
      opacity: 0.8;
    }
`;


const sprachelernen = (
    <Menu>
      {MenuDE.sprachelernen.map((data) => {
        return <MenuItem><NavLink to={data.link}>{data.item}</NavLink></MenuItem>
      })}
    </Menu>
  );
  const erwachsene = (
    <Menu>
      {MenuDE.erwachsene.map((data) => {
        return <MenuItem><NavLink to={data.link}>{data.item}</NavLink></MenuItem>
      })}
    </Menu>
  );
  const firmen = (
    <Menu>
      {MenuDE.firmen.map((data) => {
        return <MenuItem><NavLink to={data.link}>{data.item}</NavLink></MenuItem>
      })}
    </Menu>
  );
  const gefoerdertekurse = (
    <Menu>
      {MenuDE.gefoerdertekurse.map((data) => {
        return <MenuItem><NavLink to={data.link}>{data.item}</NavLink></MenuItem>
      })}
    </Menu>
  );
  const kids = (
    <Menu>
      {MenuDE.kidsteens.map((data) => {
        return <MenuItem><NavLink to={data.link}>{data.item}</NavLink></MenuItem>
      })}
    </Menu>
  );
  const learnalanguage = (
    <Menu>
      {MenuEN.learnalanguage.map((data) => {
        return <MenuItem><NavLink to={data.link}>{data.item}</NavLink></MenuItem>
      })}
    </Menu>
  );
  const adults = (
    <Menu>
      {MenuEN.adults.map((data) => {
        return <MenuItem><NavLink to={data.link}>{data.item}</NavLink></MenuItem>
      })}
    </Menu>
  );
  const business = (
    <Menu>
      {MenuEN.business.map((data) => {
        return <MenuItem><NavLink to={data.link}>{data.item}</NavLink></MenuItem>
      })}
    </Menu>
  );
  const subsidizedcourses = (
    <Menu>
      {MenuEN.subsidizedcourses.map((data) => {
        return <MenuItem><NavLink to={data.link}>{data.item}</NavLink></MenuItem>
      })}
    </Menu>
  );
  const kidsandteens = (
    <Menu>
      {MenuEN.kidsandteens.map((data) => {
        return <MenuItem><NavLink to={data.link}>{data.item}</NavLink></MenuItem>
      })}
    </Menu>
  );

class DesktopNav extends Component {

    render () {
        return (
            <DesktopnavWrapper>
              {this.props.language === "de" &&
                <NavList>
                    <NavLi> 
                    <Dropdown overlay={sprachelernen} placement="bottomRight">
                        <Button notfound={this.props.nofind}>
                            Eine Sprache lernen                            
                            <IconSpan notfound={this.props.nofind}>
                              <NavIcon />                            
                            </IconSpan>
                        </Button>
                    </Dropdown>
                    </NavLi>
                    <NavLi> 
                    <Dropdown overlay={erwachsene} placement="bottomRight">
                        <Button notfound={this.props.nofind}>
                            Erwachsene                           
                            <IconSpan notfound={this.props.nofind}>
                              <NavIcon />                             
                            </IconSpan>
                        </Button>
                    </Dropdown>
                    </NavLi>
                    <NavLi> 
                    <Dropdown overlay={firmen} placement="bottomRight">
                        <Button notfound={this.props.nofind}>
                            Firmen                         
                            <IconSpan notfound={this.props.nofind}>
                              <NavIcon />                             
                            </IconSpan>
                        </Button>
                    </Dropdown>
                    </NavLi>
                    <NavLi> 
                    <Dropdown overlay={gefoerdertekurse} placement="bottomRight">
                        <Button notfound={this.props.nofind}>
                            Geförderte Kurse                           
                            <IconSpan notfound={this.props.nofind}>
                              <NavIcon />                             
                            </IconSpan>
                        </Button>
                    </Dropdown>
                    </NavLi>
                    <NavLi> 
                    <Dropdown overlay={kids} placement="bottomRight">
                        <Button notfound={this.props.nofind}>
                            Kids & Teens                            
                            <IconSpan notfound={this.props.nofind}>
                              <NavIcon />                             
                            </IconSpan>
                        </Button>
                    </Dropdown>
                    </NavLi>
                </NavList>
             }
              {this.props.language === "en" &&
                <NavList>
                    <NavLi> 
                    <Dropdown overlay={learnalanguage} placement="bottomRight">
                        <Button notfound={this.props.nofind}>
                            Learn a Language                            
                            <IconSpan notfound={this.props.nofind}>
                              <NavIcon />                             
                            </IconSpan>
                        </Button>
                    </Dropdown>
                    </NavLi>
                    <NavLi> 
                    <Dropdown overlay={adults} placement="bottomRight">
                        <Button notfound={this.props.nofind}>
                            Adults                          
                            <IconSpan notfound={this.props.nofind}>
                              <NavIcon />                             
                            </IconSpan>
                        </Button>
                    </Dropdown>
                    </NavLi>
                    <NavLi> 
                    <Dropdown overlay={business} placement="bottomRight">
                        <Button notfound={this.props.nofind}>
                            Business                         
                            <IconSpan notfound={this.props.nofind}>
                              <NavIcon />                             
                            </IconSpan>
                        </Button>
                    </Dropdown>
                    </NavLi>
                    <NavLi> 
                    <Dropdown overlay={subsidizedcourses} placement="bottomRight">
                        <Button notfound={this.props.nofind}>
                            Subsidized Courses                           
                            <IconSpan notfound={this.props.nofind}>
                              <NavIcon />                             
                            </IconSpan>
                        </Button>
                    </Dropdown>
                    </NavLi>
                    <NavLi> 
                    <Dropdown overlay={kidsandteens} placement="bottomRight">
                        <Button notfound={this.props.nofind}>
                            Kids & Teens                            
                            <IconSpan notfound={this.props.nofind}>
                              <NavIcon />                             
                            </IconSpan>
                        </Button>
                    </Dropdown>
                    </NavLi>
                </NavList>
             }
             </DesktopnavWrapper>
        )
    }
}
export default DesktopNav;

